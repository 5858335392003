import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Image = makeShortcode("Image");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Process = makeShortcode("Process");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-no-paradigm",
      "style": {
        "position": "relative"
      }
    }}>{`❌ No Paradigm`}<a parentName="h1" {...{
        "href": "#-no-paradigm",
        "aria-label": " no paradigm permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`One concern in putting this module together has been language itself - in particular the word
"sacred". Words like this have such inertia as a result of all the uses to which they have been
put over the generations. They have been misused repeatedly in the service of violent and
oppressive narrative structures which do not truly serve our species.`}</p>
    <p>{`There is, to quote Douglas Rushkoff, `}<em parentName="p"><Link to="https://www.goodreads.com/book/show/139712.Nothing_Sacred" mdxType="Link">{`Nothing Sacred`}</Link></em>{`.
Which also means everything is sacred.`}</p>
    <p>{`What we most want to say here cannot be `}<Link to="https://youtu.be/7xwq1v8OD6s?t=255" mdxType="Link">{`said`}</Link>{`.
Instead, simply recognize that you cannot acquire everything, which is why any path to the
sacred (meaning beyond yourself) has always had to do with letting go. We are not on a hero's
journey. We are on `}<Link to="https://youtu.be/3HYlbg6RKMA?t=961" mdxType="Link">{`a fool's errand to give back the evil which does not belong`}</Link>{`
in this world. `}</p>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Inertia of Language`}</strong></p>
        <aligner {...{
          "center": ""
        }}>{`
    `}<img parentName="aligner" {...{
            "alt": "The Inertia of Language",
            "src": "/images/mod7-img/newspapers.gif"
          }}></img>
        </aligner>
        <Aligner center mdxType="Aligner">
          <Button to="https://tinyurl.com/inertia-language" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Ever Moving`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Image alt="Ever Moving" src="/images/mod7-img/mary_oliver.jpg" mdxType="Image" />
        </Aligner>
        <Aligner center mdxType="Aligner">
          <Button to="https://tinyurl.com/oliver-journey" mdxType="Button">
    Journey
          </Button>
        </Aligner>
      </Box>
    </Column>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We must take great care with the `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language/#for-the-skeptics"
      }}>{`language`}</a>{`
we use to describe our `}<a parentName="p" {...{
        "href": "/learn/module-3/intention"
      }}>{`intentions`}</a>{` when working with a global,
shared and ownerless platform for computing. This is because the plot of our narratives is
actually written in `}<a parentName="p" {...{
        "href": "/learn/module-2/money-speech/"
      }}>{`economic code`}</a>{` and `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language/#open-protocols-and-a-network-of-value"
      }}>{`executed without the context`}</a>{` stories provide. The possibilities for the kinds of transactions we can enter into are endless; this is neither a good nor a bad thing. It is simply a (very strange) fact of the time in which we live.  `}</p>
    <p>{`As `}<a parentName="p" {...{
        "href": "/learn/module-7/the-gift/#poetic-gifts-to-posterity"
      }}>{`Lewis Hyde showed`}</a>{`, it is to the poets that we must
turn to understand how to best craft this new tongue and use it in `}<a parentName="p" {...{
        "href": "/learn/module-5/incentives/#programming-crowds"
      }}>{`service`}</a>{`
of those to come, rather than for our own self-aggrandizement.`}</p>
    <p><em parentName="p">{`"Since a songline can span the lands of several different language groups, different parts of the song are said to be in those different languages. Languages are not a barrier because the melodic contour of the song describes the nature of the land over which the song passes. `}<strong parentName="em">{`The rhythm is what is crucial`}</strong>{` to understanding the song. Listening to the song of the land is the same as walking on this songline and observing the land."`}</em></p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Marianne Brün begins by discussing the tacit assumptions of a work on `}<em parentName="p">{`The Structure of
Scientific Revolutions`}</em>{` by Thomas S. Kuhn, along with the claims he makes about the term
"paradigm" and the dilemma this lands him in, which she will unpick in the course of her essay.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"In short: its greatest dynamic power is held by a paradigm while it is not called `}<em parentName="p">{`paradigm`}</em>{`,
but called facts, data, truth, nature, ethics, proper procedures, etc. As soon as a paradigm is
called a paradigm (usually then referred to as a `}<em parentName="p">{`mere paradigm`}</em>{`), its power collapses.""`}</p>
    </blockquote>
    <p>{`Assumed knowledge, like geocentrism or the existence of a particular God, creates the
framework for investigation. However, it has throughout most of human history been a taboo to question the existing framework itself:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"It was a sin and crime, punished by law, church, and community vigilance, to ask and probe whether
the known was true, whether philosophical thought and scientific research and problem-solving
were based on all one could know.""`}</p>
    </blockquote>
    <p>{`We cannot sneer at this history, knowing that the people of bygone times acted in accordance
with what they knew, and `}<strong parentName="p">{`could not`}</strong>{` have suspected that much of their knowledge was based
on flawed interpretations or faulty observations. Of course, this also implies that:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"We today can not tell, by definition, within which paradigm we are dwelling, thinking and
acting, unless and until we are able to observe us and it from the outside, just as we
recognize it and us from outside the times of Galileo.""`}</p>
    </blockquote>
    <p>{`Kuhn was the first to use the term ‘paradigm’ in this sense, and in so doing, he trapped himself in exactly the kind of paradigmatic thinking he was trying to escape. Brün points out the self-contradiction in Kuhn’s attempt to carefully explain his leap in ways that would appear reasonable to those still trapped within an old paradigm. In order to bypass this problem, Brün simply defines her stance:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I shall use the word `}<em parentName="p">{`paradigm`}</em>{` whenever I wish to speak of any structural notion and concept
which, underlying the development of discourse, is tacitly taken for granted by all participants
in that discourse, taken to go without saying and left unquestioned, regardless of whether the
discourse leads to an agreement or a disagreement on any issue.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Paradigms hold the most power when they are taken for granted by everyone and not called what?`}</p>
        <p>{`Paradigms.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "the-inertia-of-language",
      "style": {
        "position": "relative"
      }
    }}>{`The Inertia of Language`}<a parentName="h3" {...{
        "href": "#the-inertia-of-language",
        "aria-label": "the inertia of language permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Using Brün’s definition above, all of history can be seen as the retroactive telling of  paradigmatic stories:`}</p>
    <blockquote>
      <p parentName="blockquote">{`their inception, flourishing, and collapse; and how there always has been a new paradigm
waiting to substitute for the collapsed one. These stories do not, however, sufficiently
emphasize a recurrent and ubiquitous phenomenon `}{`[...]`}{` This phenomenon is best described as
`}<strong parentName="p">{`the inertia of language`}</strong>{`.`}</p>
    </blockquote>
    <p>{`However, these paradigmatic stories do not cleanly replace one another, due to ‘the inertia of language’: the tendency of our words to preserve old ways of thinking, even long after the paradigm from which they were born has been superseded.`}</p>
    <p>{`This inertia is both a symptom of paradigmatic stories, and the dynamic force behind their
telling. It can be traced through linguistic constructs like how the sun still "rises", even though we have
known for hundreds of years that it is the Earth's horizon which dips into dawn, and rises into dusk.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Due to this inertia, language stores and offers for communicative usage many `}<Link to="https://www.orwell.ru/library/essays/politics/english/e_polit/" mdxType="Link">{`remnants of many obsolete paradigms`}</Link>{`.`}</p>
    </blockquote>
    <p>{`If we accept these remnants in what we write, speak or hear, then we subject ourselves to the
unstoppable inertia behind it. This is something for which we must take `}<strong parentName="p">{`individual responsibility`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`In human society language is so powerful that only violence (which is `}<em parentName="p">{`not`}</em>{` language) can stop it. Where its power
fails to serve my desires, it would be a mistake to blame such failure on the weakness of
language. Rather I should blame the weakness of my relation to language. If I fail to notice
that I think and speak, under the influence of language, in patterns and constructs accumulated
and preserved in the junkyards of long since vanished paradigms, then this shows my lack of
consciousness with regard to just that power with which language can quickly make me spokesman
for ideologies, in which everybody is almost always “right” at the “wrong” time.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The only way to act against the inertia of language formed in long-past paradigms in to take individual `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` for the words we use.`}</p>
        <p>{`responsibility.`}</p>
      </Card>
    </Flash>
    <p>{`This rightness at the wrong time becomes most obvious in a failure to appreciate the importance of context for any creative use of language. We wish to point out that such contextual, environmental awareness is more readily available in `}<a parentName="p" {...{
        "href": "https://spectrum.library.concordia.ca/id/eprint/986506/7/Indigenous_Protocol_and_AI_2020.pdf"
      }}>{`indigenous protocols`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The work to reclaim language through these various mediums inaugurates the next leap between oral, written and textual mediums into the digital. But technology reveals what language reclamation solely through the discipline of linguistics lacks, that the creation of meaning cannot be programmed without context.`}</p>
    </blockquote>
    <h3 {...{
      "id": "re-cognition",
      "style": {
        "position": "relative"
      }
    }}>{`Re-cognition`}<a parentName="h3" {...{
        "href": "#re-cognition",
        "aria-label": "re cognition permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Brün notes that ideologues always use language which insists on its own timeless truth and consistency, yet is hostile
to critique and frames the observed evidence as a betrayal of its believed premise. Ideologues
believe in the power of language but fail to recognize it and so they become no more than the
tools of their own ideologies:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The accumulated language of bygone times: powerful, familiar, and obsolete, `}<em parentName="p">{`uses the
ideologists`}</em>{` and makes them its speakers. Through them it thwarts those specific human
attempts which we call ideas and which, rebellious against all that is, would engender new
thought and new procedures.`}</p>
    </blockquote>
    <p>{`This brings us to the crux of the dilemma faced by authors like Kuhn - and us all, really:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Neither insight nor good intention, not even syntactic and grammatical care, will protect me
from becoming an ideologist as long as I am unable or unwilling to create the suitable language
which speaks as I think and not louder than my thoughts.`}</p>
    </blockquote>
    <p>{`Overcoming this issue `}<em parentName="p">{`begins`}</em>{` by facing squarely the human misery and suffering in this world, seeing
how it is largely created as a result of our culture, ethics, morals, beliefs and values and
then attempting to join "the problem-solvers" so that we might stand ready for the day when the underlying paradigm can finally be seen clearly as a mere paradigm:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The universal paradigm, in whose invisible and unquestioned embrace human misery can
accurately be named a somehow excusable and certainly always expected commonplace, will stand
exposed and rejected, to be replaced by one that I (from my present outlook) would prefer or
(a radical change in social consciousness) by none.`}</p>
    </blockquote>
    <p>{`We're arguing for the replacement of the paradigm which casts man-made suffering as an excusable
certainty with `}<strong parentName="p">{`no paradigm at all`}</strong>{`, rather than Brün's preference, but her following analysis of the already existing paradigm is still on point.`}</p>
    <h3 {...{
      "id": "the-reward-oriented-hierarchy",
      "style": {
        "position": "relative"
      }
    }}>{`The Reward-oriented Hierarchy`}<a parentName="h3" {...{
        "href": "#the-reward-oriented-hierarchy",
        "aria-label": "the reward oriented hierarchy permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`My conjecture is that we all live, speak, and act, perceive, judge, and decide under the
unquestioned, untouchable, and firmly established guidance of an image which I call “the
reward-oriented hierarchy”. By calling it so, I may be able to show that particular dynamic
property of language which, undetected, blocks creative, and thus political, thought processes.`}</p>
    </blockquote>
    <p>{`Brün makes a clear distinction between: `}</p>
    <Process mdxType="Process">
      <p>{`The `}<strong parentName="p">{`premise`}</strong>{` of any truly human society, which is the satisfaction of all human needs `}<em parentName="p">{`before and so that`}</em>{` `}</p>
      <p>{`The `}<strong parentName="p">{`purpose`}</strong>{` of a society can be envisioned, which is better means to meet our needs; the use of freedom from need for enjoyment of diversity and difference; the exploration of ideas; and the `}<a parentName="p" {...{
          "href": "/learn/module-6/inventing-on-principle"
        }}>{`implementation of inventions`}</a>{`.`}</p>
    </Process>
    <blockquote>
      <p parentName="blockquote">{`We do not live in such a human society. Our society has developed an image of itself,
according to which the satisfaction of needs has to be deserved and earned, so that it be
understood as a reward `}{`[...]`}{` The premise of the reward-oriented hierarchy states that the
necessities for the satisfaction of human needs are scarce. This scarcity poses individual
and social problems of production and distribution. Finally, the premise declares that the
problems of scarcity can not be solved before individual and social problems are solved.`}</p>
    </blockquote>
    <p>{`Our present knowledge and technology could remove scarcity, but its `}<strong parentName="p">{`economics`}</strong>{` and `}<strong parentName="p">{`language`}</strong>{` linger on and stand in the way of us realising our power to change things. `}<a parentName="p" {...{
        "href": "https://dougengelbart.org/content/view/191/"
      }}>{`Shared networks which merge the two explicitly`}</a>{` allow us to program dynamic flows of value are fascinating. Structuring incentives according only to who gets rewarded inevitably results in imbalanced systems, which are manipulated by those already in power for their own ends. This is why balancing `}<a parentName="p" {...{
        "href": "/learn/module-6/serenity/#aligning-incentives"
      }}>{`penalties with rewards`}</a>{` and developing clear cost models for malicious behaviour is so critical. It is the last piece Brün was missing back in 1980. However, she already understood then what many still do not: `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 The problem is not primarily social or political. It is `}<em parentName="p">{`linguistic and contextual`}</em>{`.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the paradigm within which we currently live?`}</p>
        <p>{`The reward-oriented hierarchy. `}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: What does the reward-oriented hierarchy assume?`}</p>
        <p>{`That people ought to earn what they need.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Is the assumption that people ought to earn what they need a social or political problem?`}</p>
        <p>{`Neither. It is linguistic (because it depends on how we craft our stories about `}<em parentName="p">{`what value is`}</em>{`).`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "on-your-marx",
      "style": {
        "position": "relative"
      }
    }}>{`On Your Marx`}<a parentName="h3" {...{
        "href": "#on-your-marx",
        "aria-label": "on your marx permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We cannot use the language of this reward-oriented hierarchy in which we are so deeply embedded
to discuss its own premise: we must `}<Link to="https://www.youtube.com/playlist?list=PL5ClmaG2tnPPFveZLH5lRrzV-Ndc3_Nli" mdxType="Link">{`first teach it to experiment with itself`}</Link>{` in order to discover how it labors under the paradigm it ought to expose. `}</p>
    <blockquote>
      <p parentName="blockquote">{`In particular: can we find the language whose grammar, syntax, and sentence structure would
make it consistent with the premise that all human needs have to be satisfied first, `}<em parentName="p">{`before
and so that`}</em>{` individual and social problems can and will be identified and solved?`}</p>
    </blockquote>
    <p>{`Brün quotes Karl Marx and shows how his analysis of labour may be applied to language to make
a very subtle point about how insidious paradigms truly are. Though we can use Marxist analysis
to reveal the contradictions of capitalism, showing that there are contradictions is not
revolutionary. It is the contradictions themselves which are revolutionary because they
generate the antagonisms which the system cannot resolve without disintegrating. Therefore, Marxist analysis is a great starting point, but due to the inertia of its language, it inadvertently misleads its followers into focusing on the ideology rather than the insufferable contradictions. What it lacks - and what Brün dreams of - is an `}<a parentName="p" {...{
        "href": "https://sign.kerenel.community"
      }}>{`economy of signs`}</a>{` largely free from the accumulated inertia of past paradigms.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The language we are developing is an economic one whose grammar, syntax, and structure will make it consistent with what premise?`}</p>
        <p>{`Human needs must be fulfilled `}<em parentName="p">{`before and so that`}</em>{` society can flourish.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "measured-art",
      "style": {
        "position": "relative"
      }
    }}>{`Measured Art`}<a parentName="h3" {...{
        "href": "#measured-art",
        "aria-label": "measured art permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`If we are to realise Brün's dream of a society which sees the satisfaction of human need as its premise, we must`}</p>
    <blockquote>
      <p parentName="blockquote">{`become the creative artists who compose language to teach its writers and speakers how to
be thoughtfully and carefully `}<em parentName="p">{`inconsistent`}</em>{` with undesirable premises, to be `}<em parentName="p">{`incompatible`}</em>{`
with the morals, the religions, the armed forces, the arguments of the reward-oriented hierarchy.`}</p>
    </blockquote>
    <p>{`This kind of dream cannot be realised in conventional language, no matter how eloquent:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Communicative language is accumulated language based on obsolete and present paradigms and
can not speak for those of us who think and dream in another paradigm.`}</p>
    </blockquote>
    <p>{`We can turn again to David Graeber to understand what these kinds of inconsistent, incompatible ways of organizing might look like in both history and modern practice.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Anarchist-inspired revolutionary 'networks' and 'convergences' employ decision-making processes which assume that no ideological uniformity can or should be possible. Rather, these forms become ways of managing a `}<a parentName="p" {...{
          "href": "/learn/module-7/giving/#further-afield"
        }}>{`diversity`}</a>{`, even incommensurability, which is `}<strong parentName="p">{`seen as a value in itself`}</strong>{` `}{`[...]`}{` It is precisely what most outside observers take to be the foolishness and naiveté of the movement (their apparent lack of coherent ideology) `}{`[that]`}{` has turned out to be a token of their most sophisticated accomplishment and contribution to revolutionary theory.`}</p>
    </blockquote>
    <p>{`The refusal to adopt one, ‘coherent’ ideological paradigm is an essential aspect of the three freedoms outlined in Graeber's book, `}<a parentName="p" {...{
        "href": "/conversation/hospitality"
      }}>{`The Dawn of Everything`}</a>{`. The freedom to refuse, to disobey, is critical to reigniting our shared political imagination and finding better ways to live together in diverse societies.`}</p>
    <p>{`We have written this brief with the intention to indicate a `}<a parentName="p" {...{
        "href": "/learn/module-6/"
      }}>{`connected chain`}</a>{` of thought - a digital `}<Link to="https://en.wikipedia.org/wiki/Songline" mdxType="Link">{`songline`}</Link>{` of a different and human society. Following this age-old tune, Brün finishes with a note about doubt, `}<a parentName="p" {...{
        "href": "/learn/module-3/humility/"
      }}>{`humility`}</a>{` and `}<a parentName="p" {...{
        "href": "/learn/module-4/art/"
      }}>{`art`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Language is not the standard against which thinking is to be measured; on the contrary:
language is to be measured by a standard it barely reaches, if ever, namely the imagery of
human doubt and human desire `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`If the imagery succeeds in containing, anti-communicatively, for later, the simulation, the
structural analogy to that which was found wanting, then, who knows, it may tell us or someone
some day with breathtaking eloquence and in then simple terms what we, today, almost
speechlessly have wanted so much.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What do we need to embody in order to forge carefully a language which is inconsistent with undesirable premises about who deserves rewards and `}<a parentName="p" {...{
            "href": "https://thekingcenter.org/about-tkc/the-king-philosophy/"
          }}>{`incompatible with the arguments of any armed force`}</a>{`?`}</p>
        <p>{`A creative spirit.`}</p>
      </Card>
    </Flash>
    <List mdxType="List">
      <Link to="https://thebaffler.com/salvos/whats-the-point-if-we-cant-have-fun" mdxType="Link">
        <p>{`Life is an end in itself. Play requires no paradigm. Here is the ground to unthink the world around us.`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      